<template>
  <v-card class="mt-3 ml-3 pb-8">
    <v-card-title>
      <v-icon class="mr-2">mdi-view-list</v-icon>Ordenação de ofertas do clube
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span>Arraste e solte para reordenar as ofertas conforme sua preferência.</span>
      </v-tooltip>
    </v-card-title>
    <draggable v-model="homeButtons" class="ml-5" @start="dragging = true" @end="finishMove">
      <div v-for="button in homeButtons" :key="button.position" class="mt-3">
        <v-card class="d-inline-flex align-center pl-1 pr-3 pt-2 pb-2" style="width: 205px;">
          <v-icon class="">mdi-drag</v-icon>
          <span class="">{{ translateButtonType(button.type) }}</span>
        </v-card>
      </div>
    </draggable>
  </v-card>
</template>

<script>
import Draggable from 'vuedraggable'
import { QUERY_OFFERS_ORDER, MUTATION_UPDATE_SCREEN_COMPONENTS } from '@/modules/accounts/graphql'

export default {
  components: {
    Draggable
  },
  props: {
    accountId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    homeButtons: [],
    dragging: false
  }),
  apollo: {
    configuration: {
      query: QUERY_OFFERS_ORDER,
      fetchPolicy: 'network-only',
      variables() {
        return {
          accountId: this.accountId
        }
      },
      update(data) {
        if (data && data.screenComponents) {
          this.homeButtons = data.screenComponents.map(component => ({
            position: component.order,
            type: component.type
          }))
        }
      },
      error() {
        this.$alert({
          alert_message: 'Erro ao buscar a configuração',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      },
      context() {
        return {
          headers: {
            accountid: this.accountId
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    }
  },
  methods: {
    translateButtonType(type) {
      const translations = {
        ACTIVATABLE_OFFERS: 'Ofertas Ativáveis',
        CLUB_OFFERS: 'Ofertas do Clube',
        PERSONALIZED_OFFERS: 'Ofertas Personalizadas'
      }
      return translations[type] || type
    },
    checkMove(e) {
      return true
    },
    finishMove(e) {
      this.homeButtons.forEach((button, index) => {
        button.position = index + 1
      })
      const screenComponents = this.homeButtons.map(button => ({
        order: button.position,
        type: button.type
      }))
      this.updateButtonPositions(screenComponents)
      this.dragging = false
    },
    async updateButtonPositions(screenComponents) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_SCREEN_COMPONENTS,
          variables: {
            input: {
              accountId: this.accountId,
              screenComponents
            }
          },
          context: {
            uri: this.$microservicesUrls['app_config']
          }
        })
      } catch (err) {
        this.$alert({
          alert_message: 'Erro ao salvar a ordem das ofertas',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
